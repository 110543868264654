<template>
  <div>
    <b-breadcrumb style="margin-bottom:20px">
      <b-breadcrumb-item to="/">
        <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
      </b-breadcrumb-item>
      
      <b-breadcrumb-item to="/po-fail" v-if="from=='/po-fail'"
        >PO Fail List</b-breadcrumb-item
      >
      <b-breadcrumb-item to="/order" v-else-if="permission.view"
        >Purchase Order List</b-breadcrumb-item
      >
      <b-breadcrumb-item active>Detail Purchase</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body>
      <b-card-body title="Detail Purchase Order">
        <b-col cols="12">
          <b-row>
            <!-- ================================ -->
            <b-col cols="6">
              <b-row>
                <div class="d-flex flex-column">
                  <label>Purchase Order</label>
                  <p class="font-weight-bold">{{ poNumber[0] }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Customer PO</label>
                  <p class="font-weight-bold">{{ poNumber[1] }}</p>
                </div>
              </b-row>
              <b-row>
                <div
                  class="d-flex flex-column"
                >
                  <label>Dealer Name</label>
                  <p class="font-weight-bold">
                    {{ purchase.dealer.dealerName }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div
                  class="d-flex flex-column"
                >
                  <label>Industry Type</label>
                  <p class="font-weight-bold">
                    {{ purchase.distributionChannel }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Fullfillment Type</label>
                  <p class="font-weight-bold">
                    {{ purchase.orderType }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Order Status</label>
                  <p class="font-weight-bold">{{ purchase.orderStatus === 'PO Fail' ? 'Admin Review' : purchase.orderStatus }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>SAP Status</label>
                  <p class="font-weight-bold">{{ sapStatus(purchase.sapStatus) }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Purchase Date</label>
                  <p class="font-weight-bold">
                    {{ dates(purchase.updatedAt) }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Delivery Date</label>
                  <p class="font-weight-bold">
                    {{ dates(purchase.deliveryDate) }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Phone</label>
                  <p class="font-weight-bold">
                    {{ purchase.customerDetail.phone }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Email</label>
                  <p class="font-weight-bold">
                    {{ purchase.deliveryEmail }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Delivery Instruction</label>
                  <p class="font-weight-bold">
                    {{ purchase.deliveryInstruction }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <b-button
                    block
                    :disabled="loading"
                    v-show="showPrintButton"
                    variant="secondary"
                    @click="printPO()"
                    ><feather-icon size="16" icon="PrinterIcon" /> Print PO</b-button
                  >
                </div>
              </b-row>
            </b-col>

            <b-col cols="6">
              <b-row>
                <div
                  class="d-flex flex-column"
                  v-if="purchase.deliveryType === 'End Customer'"
                >
                  <label>Customer Name</label>
                  <p class="font-weight-bold">
                    {{
                      purchase.customerDetail.type === "Company"
                        ? purchase.customerDetail.companyName
                        : `${purchase.customerDetail.firstName} ${purchase.customerDetail.lastName}`
                    }}
                  </p>
                </div>

                <div
                  class="d-flex flex-column"
                  v-if="purchase.deliveryType === 'Self Collect'"
                >
                  <label>Customer Name</label>
                  <p class="font-weight-bold">
                    {{
                      purchase.customerDetail.firstName === ""
                        ? "Not Set Up"
                        : `${purchase.customerDetail.firstName} ${purchase.customerDetail.lastName}`
                    }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Customer Address</label>
                  <p class="font-weight-bold" v-if="purchase.customerDetail.address">
                    {{ purchase.customerDetail.address }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.customerDetail.address2">
                    {{ purchase.customerDetail.address2 }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.customerDetail.address3">
                    {{ purchase.customerDetail.address3 }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.deliveryAddress">
                    {{ purchase.deliveryAddress }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.deliveryAddress2">
                    {{ purchase.deliveryAddress2 }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.deliveryAddress3">
                    {{ purchase.deliveryAddress3 }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Delivery Type</label>
                  <p class="font-weight-bold">{{ purchase.deliveryType }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>SAP SO</label>
                  <p class="font-weight-bold" v-if="this.purchase.sapSo === ''">
                    Not Set Up
                  </p>
                  <p size="md" v-if="this.purchase.sapSo !== ''">
                    {{ purchase.sapSo }}
                  </p>
                </div>
              </b-row>
              <b-row v-if="this.purchase.soUpdatedDate && this.purchase.sapSo !== ''">
                <div class="d-flex flex-column">
                  <label>SO Updated Date</label>
                  <p class="font-weight-bold">{{ datesWithTime(purchase.soUpdatedDate) }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>PO Created by</label>
                  <p class="font-weight-bold" v-if="this.purchase.createdBy === ''">
                    Not Set Up
                  </p>
                  <p class="font-weight-bold" size="md" v-if="this.purchase.createdBy !== ''">
                    {{ purchase.createdBy }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Remarks</label>
                  <p class="font-weight-bold">
                    {{ purchase.remarks }}
                  </p>
                </div>
              </b-row>
            </b-col>
          </b-row>
          <!-- ============================= -->
          <!-- <b-row>

            <b-col cols="6"> </b-col>

            <b-col cols="6">
              

              
            </b-col>
          </b-row> -->
          <!-- ================================ -->
          <!-- <b-row>
            <b-col cols="6"> </b-col>
            <b-col cols="6">
              
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6"> </b-col>

            <b-col cols="6">
             
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6"> </b-col>
            <b-col cols="6"> </b-col>
          </b-row> -->

          <b-row style="margin-top:25px;">
            <h5>Purchase Order Item List</h5>
            <b-table-simple>
              <b-tr variant="secondary">
                <b-th colspan="4" >Model</b-th>
                <b-th colspan="1" >Qty</b-th>
                <b-th colspan="3"  class="text-right">Unit Price</b-th>
                <b-th colspan="4"  class="text-right">Amount</b-th>
              </b-tr>
              <b-tr v-for="(item,index) in purchase.items" :key="item">
                <b-td colspan="4">{{ item.model }}</b-td>
                <b-td colspan="1">{{ item.qty }}</b-td>
                <b-td colspan="3"  class="text-right">{{ formatter(item.price.$numberDecimal) }}</b-td>
                <b-td colspan="4"  class="text-right">{{ formatter(parseInt(item.price.$numberDecimal)*item.qty) }}</b-td>
              </b-tr>
              <b-tr variant="secondary">
                <b-th colspan="8" >Subtotal</b-th>
                <b-td colspan="3" class="text-right">{{formatter(purchase.gstCalculation.total)}}</b-td>
              </b-tr>
              <b-tr>
                <b-th colspan="8">GST {{ purchase.gstCalculation.gst }}%</b-th>
                <b-td colspan="3"  class="text-right">{{formatter(purchase.gstCalculation.gst*purchase.gstCalculation.total/100)}} </b-td>

              </b-tr>
              <b-tr variant="secondary">
                <b-th colspan="8">Grand Total</b-th>
                <b-td colspan="3" class="text-right">{{formatter(purchase.gstCalculation.grandTotal)}}</b-td>

              </b-tr>
            </b-table-simple>
          </b-row>
          
          
          
          <b-row style="margin-top:25px;">
            <h5>Delivery Order Information</h5>
            <b-table
              :items="doList"
              :fields="doFields"
              responsive
            >
              <template #cell(sapDo)="row">
                <b-link size="md" @click="toDelivery(row.item)">
                  {{ row.item.sapDo }}
                </b-link>
              </template>
              <template #cell(status)="row">
                <step-progress-daikin :totalStep="(purchase.deliveryType=='Self Collect')?3:4" :status="row.item.status"></step-progress-daikin>
              </template>
            </b-table>
          </b-row>
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { userAccess, dateFormat, dateFormatFullWithSecond } from "@/utils/utils";
import stepProgressDaikin from '@/components/StepProgressDaikin.vue'
import { logo, footer,header } from "./daikin-po-img-base64.js";

import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";

export default {
  components: {
    stepProgressDaikin
  },
  props: ["id","from"],
  data() {
    return {
      doFields: [
        { key: "sapDo", label: "DO Number" },
        { key: "sapInvoice", label: "Invoice Number" },
        { key: "status", label: "Status" },
      ],
      itemFields: [
        { key: "model", text: "Item Model" },
        { key: "qty", text: "Qty" },
        { key: "price", text: "Price" },
        // { key: "rejectedQty", text: "Rejected Qty" },
        // { key: "rejectionReason", text: "Reason" },
      ],
      doList: [],
      loading: false
    };
  },
  methods: {
    sapStatus(status){
      switch(status) {
        case 'A':
          return 'Ready'
        case 'B': 
          return 'Partially processed'
        case 'C':
          return 'Completely processed'
        case 'D':
          return 'Canceled'
        default:
          return `Unknown Status ${status}`
      }
    },
    remarksFormat(remarkString){
      if(remarkString==undefined){
        return ["",""]
      }
      var parsed= remarkString.split('\n');
      var lineOne= (parsed[0]==undefined)?"":parsed[0];
      var lineTwo= (parsed[1]==undefined)?"":parsed[1];
      
      return [lineOne,lineTwo]
    },
    dates(date) {
      return dateFormat(date);
    },
    datesWithTime(date){
      return dateFormatFullWithSecond(date)
    },
    customerName(purchase) {
      if (purchase.customer) {
        return purchase.customerDetail.nameFirst + " " + purchase.customerDetail.nameLast;
      } else {
        return (
          purchase.customerDetail.firstName +
          " " +
          purchase.customerDetail.lastName
        );
      }
    },
    formatter(value) {
      var number = value;
      if (!number) {
        number = 0;
      }
      return (Math.round(number * 100) / 100).toFixed(2);
    },
    async getDOList(sapSo) {
      //return [{sapDo:1,sapInvoice:1}]
      var filterDO = await this.$store.dispatch("delivery/getDeliveryOrder",{sapSo:'0000'+sapSo})
        if(filterDO.data.length<=0){
          console.info("undefined")
          this.doList=[]
          return
        }

        var jData = filterDO.data.map((value)=>{
          return {
            id:value._id,
            sapDo:value.sapDo,
            sapInvoice:value.sapInvoice,
            status:value.status
          }
        })
        console.info('data ',jData)
        console.info('filterDO ',filterDO.data)
        this.doList=jData
        return
      
      
    },
    toSales() {
      var sapSo = this.purchase.sapSo;
      var filterSO = this.salesOrders.find((x) => {
        return x.sapSo === sapSo;
      });
      this.$router.push({
        name: "sales-order-detail",
        params: { idSales: filterSO._id },
      });
    },
    toDelivery(doDetail) {
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: doDetail.id, from: "other" },
      });
    },
    async printPO() {
      this.loading = true;
      try {
        var customerName = this.purchase.customerDetail.type === "Company"
          ? this.purchase.customerDetail.companyName
          : `${this.purchase.customerDetail.firstName} ${this.purchase.customerDetail.lastName}`;
        let docDefinition = {
          pageMargins: [70, 120, 70, 70],
          header: function(currentPage, pageCount, pageSize) {
            return [
              {
                image: `${header}`,
                alignment: "center",
                width: 520,
                margin: [0, 50, 0, 0],
              },
            ];
          },
          footer: function(currentPage, pageCount) {
            return [
              {
                image: `${footer}`,
                alignment: "center",
                width: 600,
                margin: [0, 0, 0, 50],
              },
            ];
          },
          content: [
            { text: 'Online Purchase Order to Daikin', style: 'header' },
            { text: '\n' },
            {
              layout: 'noBorders',
              table: {
                widths: ['20%', '1%', '79%'],
                headerRows: 1,
                body: [
                  [
                    { text: 'Customer PO', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.poNumber[1], fontSize: 10 }
                  ],
                  [
                    { text: "Dealer Name", fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.dealer.dealerName, fontSize: 10 }
                  ],
                  [
                    { text: 'Online PO Ref:', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.poNumber[0], fontSize: 10 }
                  ],
                  [
                    { text: 'Fulfillment Type', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.orderType, fontSize: 10 }
                  ],
                  [
                    { text: 'SO Status', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.sapStatus(this.purchase.sapStatus), fontSize: 10 }
                  ],
                  [
                    { text: 'Purchase Date', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.dates(this.purchase.updatedAt), fontSize: 10 }
                  ],
                  [
                    { text: 'Delivery Date', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.dates(this.purchase.deliveryDate), fontSize: 10 }
                  ],
                  [
                    { text: 'Phone', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.customerDetail.phone, fontSize: 10 }
                  ],
                  [
                    { text: 'Email', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.deliveryEmail, fontSize: 10 }
                  ],
                  [
                    { text: 'Delivery Type', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.deliveryType, fontSize: 10 }
                  ],
                  [
                    { text: 'Customer Name', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: customerName, fontSize: 10 }
                  ],
                  [
                    { text: 'Delivery Address', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: [
                      this.purchase.customerDetail.address,
                      this.purchase.customerDetail.address2,
                      this.purchase.customerDetail.address3,
                      this.purchase.deliveryAddress,
                      this.purchase.deliveryAddress2,
                      this.purchase.deliveryAddress3
                    ].filter(Boolean).join('\n'), fontSize: 10 }
                  ],
                  [
                    { text: 'Delivery Instruction', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.deliveryInstruction || '', fontSize: 10 }
                  ],
                  [
                    { text: 'Remarks', fontSize: 10 },
                    { text: ':', fontSize: 10 },
                    { text: this.purchase.remarks || '', fontSize: 10 }
                  ]
                ]
              }
            },
            { text: '\n' },
            { text: 'Item Details', style: 'subheader' },
            {
              table: {
                widths: ['*', '*', '*', '*'],
                headerRows: 1,
                body: [
                  [
                    { text: 'Model', style: 'tableHeader' },
                    { text: 'Qty', style: 'tableHeader' },
                    { text: 'Unit Price', style: 'tableHeader' },
                    { text: 'Amount', style: 'tableHeader' }
                  ],
                  ...this.purchase.items.map(item => [
                    item.model,
                    item.qty.toString(),
                    this.formatter(item.price.$numberDecimal),
                    this.formatter(parseInt(item.price.$numberDecimal) * item.qty)
                  ])
                ]
              }
            },
            { text: '\n' },
            {
              layout: 'noBorders',
              table: {
                widths: ['*', '*'],
                body: [
                  [
                    { text: 'Subtotal:', alignment: 'right' },
                    { text: this.formatter(this.purchase.gstCalculation.total), alignment: 'right' }
                  ],
                  [
                    { text: `GST ${this.purchase.gstCalculation.gst}%:`, alignment: 'right' },
                    { text: this.formatter(this.purchase.gstCalculation.gst * this.purchase.gstCalculation.total / 100), alignment: 'right' }
                  ],
                  [
                    { text: 'Grand Total:', alignment: 'right', bold: true },
                    { text: this.formatter(this.purchase.gstCalculation.grandTotal), alignment: 'right', bold: true }
                  ]
                ]
              }
            }
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              alignment: 'center'
            },
            subheader: {
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 5]
            },
            tableHeader: {
              bold: true,
              fontSize: 10,
              fillColor: '#f8f9fa'
            }
          }
        };

        if (pdfMake.vfs === undefined) {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        let fileName = `PO-${this.poNumber[0]}`;
        pdfMake.createPdf(docDefinition).download(fileName);
      } catch (error) {
        console.error('Error generating PDF:', error);
        this.$bvToast.toast('Error generating PDF', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    // deliveryOrders() {
    //   return this.$store.getters["delivery/getDO"];
    // },
    purchase() {
      return this.$store.getters["purchase/purchaseById"];
    },
    poNumber(){
      if(this.purchase.length<=0){
        return ["",""]
      }
      var split = this.purchase.PONumber.split("*");
      return split;
    },
    salesOrders() {
      return this.$store.getters["sales/sales"];
    },
    permission() {
      let result = userAccess("Order", "order_menu");
      // console.log("Order",result)
      return result;
    },
    showPrintButton() {
      const status = this.purchase.orderStatus;
      return status && !['Pending', 'Draft', '', 'PO Fail'].includes(status);
    }
  },
  mounted() {
    //this.$store.dispatch("delivery/getDeliveryOrder");
    this.$store.dispatch("sales/getSales", { orderStatus: "ALL" });

    this.$store
      .dispatch("purchase/detailPurchase", {
        id: this.id,
      })
      .then((res)=>{
        this.getDOList(this.purchase.sapSo)
      })
      .catch((err) => {
        console.log({ err });
      });
  },
};
</script>
